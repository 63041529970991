import React, { useState } from 'react';
import Stripe from 'stripe';
import {
    loadStripe,
} from "@stripe/stripe-js";
import {
    Elements,
    useElements,
    useStripe,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import { useFormik } from 'formik';
import * as yup from 'yup';

const Payment = () => {
    const [couponCode, setCouponCode] = useState('');
    const [price, setPrice] = useState(100);
    const [showCard, setShowCard] = useState(false);
    const [status, setStatus] = useState(null);

    const stripe = new Stripe('sk_test_51MydLhSC2Fqs1NvmJ1Ez1MMO3RvREUqjH5NvsURLoK4iNwFc2n4ecvTGuinoaHQ2bkGJpAOkdKxI4UjVDiLzwjxc00MCkloa97');

    const stripePromise = loadStripe(
        'pk_test_51MydLhSC2Fqs1NvmrzhdQBWpMPZIsB4xKeDEbfAtBHSDlGSIWUpoQQPHdjruDrwCrBVLm0YoJiZTnK4xLAkdSOM700V0Tu2Zu6'
    );

    const applyCoupon = async () => {
        try {
            const coupon = await stripe.coupons.retrieve(couponCode);
            console.log(coupon);
            setPrice(price - (coupon.percent_off || coupon.percent_off || 0));
        } catch (error) {
            // console.log(error);
            alert('Invalid coupon code');
        }
    };

    const handleSubscribe = async (data) => {
        // Create a customer
        return new Promise(async (resolve) => {
            const customer = await stripe.customers.create({
                email: data.email,
                name: data.name,
                payment_method: data.paymentMethod,
                invoice_settings: { default_payment_method: data.paymentMethod },
            });

            const product = await stripe.products.create({
                name: "Monthly subscription",
            });

            const subscription = await stripe.subscriptions.create({
                customer: customer.id,
                items: [
                    {
                        price_data: {
                            currency: "INR",
                            product: product.id,
                            unit_amount: (price * 100).toString(),
                            recurring: {
                                interval: "month",
                            },
                        },
                    },
                ],
                coupon: 'jnECtUNw',
                payment_settings: {
                    payment_method_types: ["card"],
                    save_default_payment_method: "on_subscription",
                },
                expand: ["latest_invoice.payment_intent"],
            });

            console.log(subscription);
            resolve({
                message: "Subscription successfully initiated",
                clientSecret: subscription.latest_invoice.payment_intent.client_secret,
            });
        })
    };

    // ====================================

    function PaymentForm() {
        const stripe = useStripe();
        const element = useElements();

        const initialValues = {
            fullName: 'Harsh Patel',
            email: 'harsh@m.com',
            couponCode: '',
            cardNumber: '',
        };

        const validationSchema = yup.object({
            fullName: yup.string().required('Full Name cannot be empty'),
            email: yup.string().email('Enter valid Email').required('Email cannot be empty'),
            // cardNumber: yup.string().required('Card Number cannot be empty').matches(/^[0-9]{13,19}$/, 'Enter valid Card Number'),
            couponCode: '',
        });

        const { handleBlur, handleChange, handleSubmit, values, errors, touched, setFieldValue } = useFormik({
            initialValues,
            validationSchema,
            onSubmit: () => createSubscription(),
        })

        const createSubscription = async () => {
            try {
                const paymentMethod = await stripe.createPaymentMethod({
                    type: "card",
                    card: element.getElement(CardNumberElement),
                });

                setStatus('PENDING');

                console.log(paymentMethod);

                const data = await handleSubscribe({
                    paymentMethod: paymentMethod.paymentMethod.id,
                    email: values.email,
                    name: values.fullName,
                });

                console.log(data);
                const confirm = await stripe.confirmCardPayment(data.clientSecret);
                if (confirm.error) {
                    console.log('error is ', confirm.error);
                    setStatus('FAILURE');
                    setShowCard(false);
                    return alert("Payment failed!");
                } else {
                    // alert("Payment Successful! Subscription active.");
                    setStatus('SUCCESS');
                    setShowCard(false);
                }
            } catch (err) {
                console.error(err);
                // alert("Payment failed! " + err.message);
                setStatus('FAILURE');
                setShowCard(false);
            }
        };

        const cardElementOptions = {
            style: {
                base: {
                    fontSize: "16px",
                    color: "#000",
                    "::placeholder": {
                        color: "#666"
                    },
                },
                invalid: {
                    color: "#424770"
                },
            }
        };

        return (
            <div className='card-container'>
                <div className='card'>
                    <span onClick={() => setShowCard(false)} className='close'>x</span>
                    <label>Full name *</label>
                    <input
                        type="text"
                        value={values.fullName}
                        onChange={handleChange('fullName')}
                        onBlur={handleBlur('fullName')}
                        placeholder='john brao'
                    />

                    {touched.fullName && errors.fullName && (
                        <div className='err'>{errors.fullName}</div>
                    )}

                    <label>Email *</label>
                    <input
                        type="text"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                        placeholder='example@mail.com'
                    />

                    {touched.email && errors.email && (
                        <div className='err'>{errors.email}</div>
                    )}

                    <label>Card Number *</label>
                    <CardNumberElement
                        className='card-input card-num'
                        options={cardElementOptions}
                        id='card-num'
                    />
                    {/* {touched.cardNumber && errors.cardNumber && (
                        <div className='err'>{errors.cardNumber}</div>
                    )} */}

                    <label>Card Expiry *</label>
                    <CardExpiryElement className='card-input card-exp' options={cardElementOptions} id='card-exp' />
                    <label>Card CVC *</label>
                    <CardCvcElement className='card-input card-cvc' options={cardElementOptions} id='card-cvc' />

                    <div className='coupon-container'>
                        <label>Coupon code</label> <br />
                        <section>
                            <input
                                type="text"
                                placeholder="Coupon code"
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                            />
                            <button onClick={applyCoupon}>Apply</button>
                        </section>
                    </div>

                    <button onClick={handleSubmit}>Subscribe</button>
                </div>
            </div>
        );
    };

    const Pending = () => (
        <>
            <div className='loader'></div>
            <p>We are processing your payment, please don't reload the page</p>
        </>
    );

    const Success = () => (
        <div className='success'>
            <img src={require('../assets/images/success.jpeg')} className='status-icon' />
            <p>Congratulations! Your payment was successful, and your subscription is now active.</p>
            <button className='got-it' onClick={() => setStatus(null)}>Got It</button>
        </div>
    )

    const Fail = () => (
        <div className='fail'>
            <img src={require('../assets/images/fail.png')} className='status-icon' />
            <p>We are sorry! Your payment was fail.</p>
            <button className='got-it' onClick={() => setStatus(null)}>Got It</button>
        </div>
    )

    const plans = [{ name: 'Bronze', price: 99 }, { name: 'Silver', price: 199 }, { name: 'Gold', price: 299 }]

    return (
        <div>
            {status && (
                <div className='status-container'>
                    <div className='status'>
                        {
                            status === 'PENDING' ? (
                                <Pending />
                            ) : status === 'SUCCESS' ? (
                                <Success />
                            ) : status === 'FAILURE' ? (
                                <Fail />
                            ) : <></>
                        }
                    </div>
                </div>
            )}
            {/* <h2>Total amount: {price}</h2> */}
            <div className='pricing-table'>
                {plans.map(item => (
                    <div className='plan'>
                        <div className='plan-name'>{item.name}</div>
                        <div className='price-wrapper'>
                            <div className='price'>{item.price}₹/Month</div>
                        </div>
                        <div className='list-wrapper'>
                            <div className='list'>
                                <span><img src={require('../assets/images/check.png')} /> Web development</span>
                                <span><img src={require('../assets/images/check.png')} /> Web development</span>
                                <span><img src={require('../assets/images/check.png')} /> Web development</span>
                                <span><img src={require('../assets/images/check.png')} /> Web development</span>
                                <span><img src={require('../assets/images/check.png')} /> Web development</span>
                                <span><img src={require('../assets/images/check.png')} /> Web development</span>
                                <span><img src={require('../assets/images/check.png')} /> Web development</span>
                            </div>
                        </div>
                        <button onClick={() => setShowCard(true)}>Subscribe</button>
                    </div>
                ))}
            </div>

            {showCard && (<Elements stripe={stripePromise}>
                <PaymentForm />
            </Elements>)}
        </div>
    );
};

export default Payment;
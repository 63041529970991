import React from 'react'
import Payment from './components/_payment';
import './App.css';

const App = () => {
  return (<>
    <Payment />
  </>);
}

export default App;